<!-- CTA Start -->
<div class="section section-padding">
    <div class="container">
        <div class="section-title text-center">
            <p class="subtitle">WAYS WE CAN HELP</p>
            <h4 class="title">Angels Ready To Help</h4>
        </div>
        <div class="row position-relative">
            <div class="col-md-6">
                <div class="sigma_cta secondary-bg">
                    <img class="d-none d-lg-block" src="assets/img/cta/1.png" alt="cta">
                    <div class="sigma_cta-content">
                        <span class="fw-600 custom-primary">Need Help, Call Our HOTLINE!</span>
                        <h4 class="text-white">+1 212-683-9756</h4>
                    </div>
                </div>
            </div>
            <span class="sigma_cta-sperator d-none d-lg-flex">or</span>
            <div class="col-md-6">
                <div class="sigma_cta primary-bg">
                    <div class="sigma_cta-content">
                        <form>
                            <label class="mb-0 text-white">Our Church Newsletter</label>
                            <div class="sigma_search-adv-input">
                                <input type="text" class="form-control" placeholder="Enter email address" name="search">
                                <button type="submit" name="button"><i class="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                    <img class="d-none d-lg-block" src="assets/img/cta/2.png" alt="cta">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CTA End -->